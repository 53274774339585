import React from 'react';
import {
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Col,
    Row, Carousel,
} from 'react-bootstrap';

import banner1 from '../assets/images/lp/banner1.png';
import mulher from '../assets/images/lp/mulher.png';
import logo from '../assets/images/logo-w.png';
import logoToksaude from '../assets/images/lp/partner/toksaude.png';
import fig001 from '../assets/images/lp/teleconsulta.png';
import fig002 from '../assets/images/lp/enfermagem.png';
import fig003 from '../assets/images/lp/monitoramento.png';
import fig004 from '../assets/images/lp/adulto-infantil.png';
import fig005 from '../assets/images/lp/prescricao.png';
import fig006 from '../assets/images/lp/telepsicologia.png';
import fig007 from '../assets/images/lp/agenda.png';
import fig008 from '../assets/images/lp/carencia.png';
import icon01 from '../assets/images/lp/icon/ico-alergia.png';
import icon02 from '../assets/images/lp/icon/ico-psiquiatria.png';
import icon03 from '../assets/images/lp/icon/ico-ginecologia.png';
import icon04 from '../assets/images/lp/icon/ico-dermato.png';
import icon05 from '../assets/images/lp/icon/ico-oftalmo.png';
import icon06 from '../assets/images/lp/icon/ico-neurologia.png';
import icon07 from '../assets/images/lp/icon/ico-pediatria.png';
import icon08 from '../assets/images/lp/icon/ico-cardio.png';
import icon09 from '../assets/images/lp/icon/ico-ginecologia.png';
import icon10 from '../assets/images/lp/icon/ico-otorrino.png';
import icon12 from '../assets/images/lp/icon/ico-odonto.png';
import icon13 from '../assets/images/lp/icon/ico-odontopediatria.png';
import icon14 from '../assets/images/lp/icon/ico-cirurgia.png';
import icon15 from '../assets/images/lp/icon/ico-sos.png';
import icon16 from '../assets/images/lp/icon/ico-canal.png';
import icon17 from '../assets/images/lp/icon/ico-gengiva.png';
import icon18 from '../assets/images/lp/icon/ico-restauracao.png';
import icon19 from '../assets/images/lp/icon/ico-semCapacitacao.png';
import icon20 from '../assets/images/lp/icon/ico-semlimite.png';
import icon11 from '../assets/images/lp/icon/ico-endocrino.png';
import partner01 from '../assets/images/lp/partner/drogasil.png';
import partner02 from '../assets/images/lp/partner/pacheco.png';
import partner03 from '../assets/images/lp/partner/saopaulo.png';
import partner04 from '../assets/images/lp/partner/raia.png';
import partner05 from '../assets/images/lp/partner/paguemenos.png';
import partner06 from '../assets/images/lp/partner/extrafarma.png';
import icoEmail from '../assets/images/lp/icon/email.png';
import icoPhone from '../assets/images/lp/icon/ligacao.png';

const sectionFig = {
    beneficios: [
        {label: 'Teleconsulta 24 horas', img: fig001},
        {label: 'Triagem com enfermagem', img: fig002},
        {label: 'Monitoramento pós-teleconsulta', img: fig003},
        {label: 'Adulto e infantil', img: fig004},
    ],
    beneficios2: [
        {label: 'Prescrição 100% digital', img: fig005},
        {label: 'Telepsicologia', img: fig006},
        {label: 'Atendimento rápido', img: fig007},
        {label: 'Sem carência', img: fig008},
    ],
    especialidades: [
        {label: 'Alergia Imunologia', img: icon01},
        {label: 'Psiquiatria', img: icon02},
        {label: 'Ginecologia', img: icon03},
        {label: 'Dermatologia', img: icon04},
        {label: 'Oftalmologia', img: icon05},
        {label: 'Neurologia', img: icon06},
        {label: 'Pediatria', img: icon07},
        {label: 'Cardiologia', img: icon08},
        {label: 'Geriatria', img: icon09},
        {label: 'Otorrinolaringologia', img: icon10},
        {label: 'Endocrinologia', img: icon11},
    ],
    drugstore: [
        {label: 'Drogasil', img: partner01},
        {label: 'Pacheco', img: partner02},
        {label: 'São Paulo', img: partner03},
        {label: 'Droga Raia', img: partner04},
        {label: 'PagueMenos', img: partner05},
        {label: 'ExtraFarma', img: partner06},
    ],
    dentalPlan: [
        {label: 'Consultas Odontologicas', img: icon12},
        {label: 'Odontopediatria', img: icon13},
        {label: 'Cirurgias', img: icon14},
        {label: 'SOS Odonto 24h', img: icon15},
        {label: 'Tratamento de Canal', img: icon16},
        {label: 'Tratamento de Gengiva', img: icon17},
        {label: 'Restaurações e Obstruções', img: icon18},
        {label: 'Sem capacitação', img: icon19},
        {label: 'Sem limite de utilizações', img: icon20},
    ],
};

const Home = () => {

    return (
        <>
            <Navbar expand="lg" className="fixed-top bg-primary shadow">
                <Container>
                    <NavbarBrand href="/">
                        <img src={logo} alt="logo" width="200px"/>
                    </NavbarBrand>
                    <NavItem className={'mt-2 mb-2'}>
                        <a href="/login" className="btn btn-outline-secondary text-white"
                           style={{
                               background: 'linear-gradient(to right, #27ABBF, #0386AE)',
                           }}>
                            FAÇA SUA TELECONSULTA
                        </a>
                    </NavItem>
                </Container>
            </Navbar>
            <div style={{
                background: 'linear-gradient(to right, #EFEFEF, #EFEFEF)',
                marginTop: '69px',
            }}
                 className="fixed-top mt-6 shadow-sm">
                <Container
                    className="d-flex justify-content-center align-items-center">
                    <Nav justified fill>
                        <NavItem className={'me-3 mt-2 mb-2'}>
                            <NavLink href="#beneficios"
                                     className={'text-white rounded-4 me'}
                                     style={{
                                         width: '250px',
                                         background: 'linear-gradient(to right, #27ABBF, #0386AE)',
                                     }}>
                                BENEFÍCIOS
                            </NavLink>
                        </NavItem>
                        <NavItem className={'mt-2 mb-2'}>
                            <NavLink href="#atendimento"
                                     className={'text-white rounded-4'}
                                     style={{
                                         width: '250px',
                                         background: 'linear-gradient(to right, #27ABBF, #0386AE)',
                                     }}>
                                CANAIS DE ATENDIMENTO
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </div>
            <div style={{paddingTop: '110px'}}>
                <img src={banner1} className="img-fluid" alt=""/>
            </div>
            <section
                id="beneficios"
                style={{
                    background: 'linear-gradient(to right, #034076, #070833)',
                }}
            >
                <Container className="py-5">
                    <div className="mb-5 mx-auto text-center">
                        <h2 className={'text-white'}>
                            CONSULTAS MÉDICAS RÁPIDAS E FÁCEIS, <br/>
                            <div className={'fw-bold'}>
                                ONDE VOCÊ ESTIVER!
                            </div>
                        </h2>
                        <p className={'text-white'}>
                            Ative Doctor, Beneficiando & Simplificando sua vida.
                        </p>
                    </div>
                    <Row className="mx-5 g-4">
                        {sectionFig.beneficios.map((item, index) => (
                            <Col xs={12} md={6} lg={3}>
                                <div
                                    className="d-flex justify-content-between align-items-center flex-column gap-2 py-4 px-2 rounded-4 shadow shadow-md text-white border border-3 border-white"
                                    style={{maxHeight: '150px'}}>
                                    <img src={item.img} alt="" className="img-fluid"
                                         style={{height: '68px'}}/>
                                    <small>{item.label}</small>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section id="especialidades">
                <Container className="py-5">
                    <div className="w-50 mx-auto text-center">
                        <h2>
                            MAIS DE <span style={{fontWeight: 1000}}
                                          className={'text-primary h1'}>25</span> ESPECIALIDADES
                        </h2>
                    </div>
                    <div className="w-75 mx-auto text-center">
                        <Row className="mt-4 g-4">
                            {sectionFig.especialidades.map((item, index) => (
                                <Col xs={6} md={3} lg={3}>
                                    <div
                                        className="d-flex flex-column justify-content-center align-items-center gap-2 p-3 rounded-4 shadow shadow-md bg-white"
                                        style={{maxHeight: '100px'}}>
                                        <img src={item.img} alt="" className="img-fluid"
                                             style={{height: '50px'}}/>
                                        <small>{item.label}</small>
                                    </div>
                                </Col>
                            ))}
                            <Col xs={6} md={3} lg={3}>
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center gap-2 p-3 rounded-4 shadow shadow-md bg-secondary">
                                    <img style={{height: '70px'}} alt=""/>
                                    <span className="text-center position-absolute text-white">
                    E muito mais...
                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section id="beneficios"
                     style={{
                         background: 'linear-gradient(to right, #034076, #070833)',
                     }}
                     className="py-5">
                <div className="mb-3 w-50 mx-auto text-center">
                    <h2 className="text-white">
                        Ainda mais <text className={'fw-bold'}>Benefícios!</text>
                    </h2>
                </div>
                <Row className="mx-5 g-4">
                    {sectionFig.beneficios2.map((item, index) => (
                        <Col xs={12} md={6} lg={3}>
                            <div
                                className="d-flex justify-content-between align-items-center flex-column gap-2 py-4 px-2 rounded-4 shadow shadow-md text-white border border-3 border-white">
                                <img src={item.img} alt="" className="img-fluid"
                                     style={{height: '68px'}}/>
                                <small>{item.label}</small>
                            </div>
                        </Col>
                    ))}
                </Row>
            </section>
            <section id="drogaria">
                <div className="py-5 w-50 mx-auto text-center">
                    <h2>
                        Descontos em Medicamentos
                    </h2>
                    <p>Até 45% de desconto em medicamentos nas principais drogarias
                        do
                        país.</p>
                </div>
                <Carousel
                    indicators={false}
                    interval={1500}
                    className="mb-5"
                >
                    {sectionFig.drugstore.map((item, index) => (
                        <Carousel.Item key={index}>
                            <div
                                className="d-flex justify-content-center align-items-center flex-column gap-2 py-4 px-2 rounded-4 shadow shadow-md"
                            >
                                <img src={item.img} alt="" className="img-fluid"/>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </section>
            <section id={'planos'}
                     style={{
                         background: 'linear-gradient(to right, #034076, #070833)',
                     }}
                     className={'py-4'}>
                <Container>
                    <Row className="g-4 align-items-center">
                        <Col xs={12} md={6}>
                            <h2 className="text-white">Plano Odontológico</h2>
                            <p className="text-white mb-4">ROL de Cobertura da ANS</p>
                            <h1 className="text-white fw-bold">+25 mil</h1>
                            <p className="text-white fw-bold">
                                Pontos de Atendimento em todo o Brasil.
                            </p>
                            <h1 className="text-white fw-bold">+2 mil</h1>
                            <p className="text-white mb-5 fw-bold">Municípios</p>
                            <small className="text-white fw-bold">Carências:</small>
                            <small className="text-white mb-4 d-block"
                                   style={{maxWidth: '450px'}}>
                                Consultas 30 dias, Prevenção 60 dias, Raio-X Simples 60 dias,
                                Odontopediatria 90 dias, Periodontia 90 dias, Cirurgia 120
                                dias,
                                Endodontia 120 dias, Raio-X Panorâmico 180 dias, Próteses 180
                                dias.
                            </small>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row className="g-2">
                                {sectionFig.dentalPlan.map((item, index) => (
                                    <Col key={index} xs={12} md={6} lg={4}>
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center gap-2 p-2 rounded-4 shadow text-white border border-3 border-white"
                                            style={{maxHeight: '110px'}}>
                                            <img
                                                src={item.img}
                                                alt={item.label}
                                                className="img-fluid"
                                                style={{height: '60px'}}
                                            />
                                            <small>{item.label}</small>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={'SOS'}>
                <Row className="g-4">
                    <Col xs={6} md={6} className={"justify-content-center align-items-center"}>
                        <div className="text-center p-5">
                            <h2 className={'h1 mt-4 mb-5 fw-bold'}>
                                SOS Odonto 24h <br/>
                                TeleUrgência <br/>
                                Odontológica
                            </h2>
                            <p className="mb-5">
                                Você liga e fala com o dentista, 24h por dia, em caso de dor
                                de dente, onde você estiver.
                            </p>
                            <div
                                className="d-inline-flex flex-column gap-2 p-2 rounded-4 border border-4 border-primary">
                                <h1 className="text-primary">0800.748.7848</h1>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={6}>
                        <div className="d-flex justify-content-end align-items-start">
                            <img src={mulher}
                                 alt=""
                                 className="img-fluid flex-column"
                                 style={{"margin-top": "-30px"}}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
            <section id={'Assistencia'}
                     style={{
                         background: 'linear-gradient(to right, #034076, #070833)',
                     }}
                     className={'py-4'}>
                <Container>
                    <h2 className="text-white mb-4">Assistência Funeral</h2>
                    <h5 className="text-white mb-2">Cobertura até R$3.300,00</h5>
                    <small className="text-white mb-4 d-block">
                        Garante ao(s) beneficiário(s) o pagamento de indenização em caso
                        de falecimento do Segurado Titular,
                        quando este ocorrer dentro do período de cobertura,
                        qualquer que seja a causa.
                    </small>
                    <h5 className="text-white mb-2">Forma de Pagamento</h5>
                    <small className="text-white mb-4 d-block">
                        O segurado poderá escolher livremente entre o pagamento da
                        indenização
                        ou a utilização da prestação de serviços de assistência funeral em
                        caso de falecimento do Segurado Titular,
                        de acordo com o padrão e plano contratados para esta garantia.
                    </small>
                </Container>
            </section>
            <section id="atendimento">
                <Container className="py-4">
                    <Row className="g-4 ">

                        <Col xs={12} md={3} lg={3}
                             className="rounded-4 border border-5 border-primary text-center d-flex flex-column justify-content-center align-items-center me-5"
                        >
                            <h2>Atendimento ao cliente Ative Doctor</h2>
                            <div
                                className="mt-2 mb-2 d-flex justify-content-center align-items-center">
                                <img className="me-3" src={icoEmail} style={{height: 30}}
                                     alt=""/>
                                <img src={icoPhone} style={{height: 30}} alt=""/>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={8}
                             className="rounded-4 border border-5 border-primary text-center d-flex flex-column flex-md-row justify-content-center align-items-center"
                        >

                            <div className="contact-item text-center mb-3 mb-md-0 me-md-3">
                                <img src={icoPhone} alt="" style={{height: 30}}/>
                                <span className="m-0 mt-1 h4"> (27) 99946-3216</span>
                                <br/>
                                <small>Whatsapp</small>
                            </div>

                            <div className="contact-item text-center mb-3 mb-md-0 me-md-3">
                                <img src={icoPhone} alt="" style={{height: 30}}/>
                                <span className="m-0 mt-1 h6"> (27) 99946-3216</span>
                                <br/>
                                <span className="h6">0800-000-7755</span>
                            </div>

                            <div className="contact-item text-center">
                                <img src={icoEmail} alt="" style={{height: 30}}/>
                                <span
                                    className="m-0 mt-1 h6"> telemedicina@ativedoctor.com</span>
                                <br/>
                                <span className="h6">sac@ativedoctor.com</span>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className="text-white"
                    style={{
                        background: 'linear-gradient(to right, #034076, #070833)',
                    }}>
                <Container className="py-4">
                    <Row>
                        <Col xs={4} md={2}>
                            <a href="https://www.toksaude.com.br/" target="_blank"
                               rel="noreferrer">
                                <img src={logoToksaude} alt="TokSaúde"
                                     className="img-fluid w-50"/>
                            </a>
                        </Col>
                        <Col xs={8} md={{offset: 6, span: 4}}
                             className="text-center d-flex align-items-center">
                            <a href="https://saude.ativedoctor.com.br/termos-de-uso.pdf"
                               target="_blank"
                               className="btn btn-link text-white"
                               rel="noreferrer">
                                Termos de Uso
                            </a>
                            {/*<a href="https://saude.ativedoctor.com.br/regulamento-sorteio.pdf" target="_blank"*/}
                            {/*   className="btn btn-link text-white"*/}
                            {/*   rel="noreferrer">*/}
                            {/*    Regulamento do sorteio.*/}
                            {/*</a>*/}
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Home;
