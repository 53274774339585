import React, {useEffect, useState} from 'react';
import {
  Card, CardBody,
  Dropdown,
  InputGroup,
  Table,
  ToastContainer,
} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {BsSearch} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import api from '../helpers/api';
import Records from '../components/Records';
import {useAuth} from '../hooks/useAuth';
import FigNoResult from '../assets/images/fig-no-result.png';
import CustomToast from '../components/CustomToast';
import moment from 'moment';
import {MdDateRange, MdViewList} from 'react-icons/md';
import {BiUser} from 'react-icons/bi';
import {FiLogOut} from 'react-icons/fi';

const MedicalRecord = () => {
  const currentDate = moment().format('YYYY-MM-DD');
  const [medicalRecordDate, setMedicalRecordDate] = useState([]);
  const [date, setDate] = useState('');
  const [toast, setToast] = useState({});
  const [dependents, setDependents] = useState([]);
  const [selectedDependent, setSelectedDependent] = useState('');
  const {logout, user} = useAuth();
  const {Id, FullName, OwnerId} = {...user};

  const getMedicalRecords = async (dependentId) => {
    const allRecords = [];
    let pageNum = 0;
    let pageCount = 1;
    const patientId = dependentId || OwnerId || Id;

    try {
      while (pageNum < pageCount) {
        const params = {
          dateMedicalRecords: currentDate,
          status: '4',
          patientId,
        };

        const resp = await api.get('video/atendimentos', {
          headers: {
            'X-Page-Num': pageNum,
            'X-Order-By': 'scheduleAt|DESC',
          },
          params,
        });

        const {data, pageNum: newPageNum, pageCount: newPageCount} = resp.data;
        allRecords.push(...data);

        pageNum = newPageNum + 1;
        pageCount = newPageCount;
      }

      setMedicalRecordDate(allRecords);
    } catch (err) {
      const text = err.response?.data?.errors[0];
      setToast({
        show: true,
        variant: 'danger',
        title: 'Erro',
        content: text,
      });
    }
  };

  const fetchDependents = async () => {
    try {
      const URL_ = `/titular/${user.OwnerId}/dependentes`;
      const METHOD = 'GET';
      const resp = await api({url: URL_, method: METHOD});
      setDependents(resp.data.data);
    } catch (error) {
      setToast({
        show: true,
        title: 'Erro',
        variant: 'error',
        content: 'Erro ao carregar dependentes',
        delay: 3000,
      });
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleDependentChange = (dependentId) => {
    setSelectedDependent(dependentId);
    getMedicalRecords(dependentId);
  };

  useEffect(() => {
    fetchDependents();
    getMedicalRecords();
  }, []);

  return (
      <>
        <Container className="py-4">
          <Row className="g-2 mb-2">
            <Col xs={12} md={9}>
              <h1 className="mb-0 text-primary">Prontuários</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-4 g-2">
                <Col lg={4} xs={12}>
                  <Form.Select
                      value={selectedDependent}
                      onChange={(e) => handleDependentChange(
                          e.target.value)}
                      className="border-primary"
                  >
                    <option value="">{FullName} </option>
                    {dependents.map((dep) => (
                        <option key={dep.Id} value={dep.Id}>
                          {dep.FullName}
                        </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={3} xs={12}>
                  <Link to={'/scheduling'}
                        className={'d-grid rounded text-decoration-none'}>
                    <Button
                        className={'d-flex align-items-center justify-content-center gap-2'}
                        variant={'outline-primary'}>
                      <MdDateRange/> Agendar
                    </Button>
                  </Link>
                </Col>
                <Col lg={3} xs={7}>
                  <Link to={'/dashboard'}
                        className={'d-grid rounded text-decoration-none'}>
                    <Button
                        className={'d-flex align-items-center justify-content-center gap-2'}
                        variant={'outline-primary'}>
                      <MdViewList/> Agendamentos
                    </Button>
                  </Link>
                </Col>
                <Col lg={2} xs={5}>
                  <Dropdown>
                    <Dropdown.Toggle variant={'outline-primary'}
                                     className={'w-100'}>
                      Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={'border-1 border-light'}>
                      <Dropdown.Item href={'/account'}>
                        <Button
                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                            variant={'outline-primary'}>
                          <BiUser/> Alterar Senha
                        </Button>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>
                        <Button
                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                            variant={'outline-primary'}>
                          <FiLogOut/> Desconectar
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row className={'mt-5'}>
                {medicalRecordDate.length <= 0 && (
                    <Row className="justify-content-center">
                      <Col xs="auto" className="text-center mb-4">
                        <img src={FigNoResult} alt="Imagem de pergunta"
                             className="mb-4 img-fluid"
                             style={{width: '300px'}}/>
                        <span className="text-primary d-block fw-bold">
                        Não há histórico para ser exibido.
                      </span>
                      </Col>
                    </Row>
                )}
                {medicalRecordDate.length > 0 && (
                    <Records outData={medicalRecordDate}
                             changeDate={date}
                    />
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        <div className={'top-center'}>
          <ToastContainer id={'toastcontainer'}
                          position={'top-center'}
                          className={'p-3'}
                          style={{zIndex: 1}}>
            {toast.show && (
                <CustomToast delay={toast.delay} title={toast.title}
                             content={toast.content} variant={toast.variant}
                             show={toast.show}/>
            )}
          </ToastContainer>
        </div>
      </>
  );
};

export default MedicalRecord;