import React, {useEffect, useState} from 'react';
import {
  Card,
  CardBody,
  Dropdown,
  Table,
  ToastContainer,
   Spinner,
} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {BiUser} from 'react-icons/bi';
import {FiLogOut} from 'react-icons/fi';
import {MdDateRange} from 'react-icons/md';
import moment from 'moment';
import {Link} from 'react-router-dom';
import api from '../helpers/api';
import CustomPagination from '../components/CustomPagination';
import {useAuth} from '../hooks/useAuth';
import FigQuestion from '../assets/images/fig-question.png';
import CustomToast from '../components/CustomToast';
import {FaHeartbeat} from 'react-icons/fa';

const Dashboard = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const [appointmentDate, setAppointmentDate] = useState([]);
    const [subscriber, setSubscriber] = useState([]);
    const [date, setDate] = useState('');
    const [toast, setToast] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDependent, setSelectedDependent] = useState('');
    const [dependents, setDependents] = useState([]);
    const [loading, setLoading] = useState(false);
    const {logout, user} = useAuth();
    const {Id, FullName, OwnerId} = {...user};

    const getAppointments = async (dependentId) => {
        try {
            setLoading(true);
            const patientId = dependentId || user.OwnerId || Id;
            const params = {
                dateAppointment: currentDate,
                status: '1,2,6',
                patientId,
            };
            const resp = await api.get('video/atendimentos', {params});
            const {data} = resp.data;
            setAppointmentDate(data);
        } catch (err) {
            const text = err.response?.data?.errors[0];
            setToast({
                show: true,
                variant: 'danger',
                title: 'Erro',
                content: text,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchDependents = async () => {
        try {
            const URL_ = `/titular/${user.OwnerId}/dependentes`;
            const METHOD = 'GET';
            const resp = await api({url: URL_, method: METHOD});
            setDependents(resp.data.data);
        } catch (error) {
            setToast({
                show: true,
                title: 'Erro',
                variant: 'error',
                content: 'Erro ao carregar dependentes',
                delay: 3000,
            });
        }
    };

    const getSubscribers = async () => {
        try {
            const URL_ = `/assinante/${user.OwnerId}/`;
            const METHOD = 'GET';
            const resp = await api({url: URL_, method: METHOD});
            setSubscriber(resp.data.data);
        } catch (error) {
            setToast({
                show: true,
                title: 'Erro',
                variant: 'error',
                content: error.message,
                delay: 3000,
            });
        }
    };

  const handleLogout = () => {
    logout();
  };

  const sortAppointmentsByDate = (appointments) => {
    if (!appointments || appointments.length === 0) {
      return [];
    }
    return appointments.sort(
        (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
  };

  const currentItems = sortAppointmentsByDate(appointmentDate);

  useEffect(() => {
    getAppointments();
    getSubscribers();
    fetchDependents();
  }, []);

  const handleDependentChange = (dependentId) => {
    setSelectedDependent(dependentId);
    getAppointments(dependentId);
  };
  return (
      <>
        <Container className="py-4">
          <Row className="g-2 mb-2">
            <Col xs={12} md={9}>
              <h1 className="mb-0 text-primary" >Agendamentos</h1>
            </Col>
            <Col xs={12} md={3}>
              {subscriber.DrawEnabled && (
                  <Card className="bg-dark p-1">
                    <CardBody className="p-1 text-center">
                      <span className="small text-secondary">
                        Número da sorte: </span>
                      <strong className="text-secondary">{subscriber?.DrawNumber}</strong>
                    </CardBody>
                  </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-4 g-2">
                <Col lg={4} xs={12}>
                  <Form.Select
                      value={selectedDependent}
                      onChange={(e) => handleDependentChange(
                          e.target.value)}
                      className="border-primary"
                  >
                    <option value="">{FullName} </option>
                    {dependents.map((dep) => (
                        <option key={dep.Id} value={dep.Id}>
                          {dep.FullName}
                        </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={3} xs={12}>
                  <Link to={'/scheduling'}
                        className={'d-grid rounded text-decoration-none'}>
                    <Button
                        className={'d-flex align-items-center justify-content-center gap-2'}
                        variant={'outline-primary'}>
                      <MdDateRange/> Agendar
                    </Button>
                  </Link>
                </Col>
                <Col lg={3} xs={7}>
                  <Link to={'/medicalRecord'}
                        className={'d-grid rounded text-decoration-none'}>
                    <Button
                        className={'d-flex align-items-center justify-content-center gap-2'}
                        variant={'outline-primary'}>
                      <FaHeartbeat/> Prontuário
                    </Button>
                  </Link>
                </Col>
                <Col lg={2} xs={5}>
                  <Dropdown>
                    <Dropdown.Toggle variant={'outline-primary'}
                                     className={'w-100'}
                    >
                      Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={'border-1 border-light'}>
                      <Dropdown.Item href={'/account'}>
                        <Button
                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                            variant={'outline-primary'}>
                          <BiUser/> Alterar Senha
                        </Button>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>
                        <Button
                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                            variant={'outline-primary'}>
                          <FiLogOut/> Desconectar
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {loading ? (
                  <Row className="justify-content-center p-4">
                    <Spinner animation="border" role="status"/>
                  </Row>
              ) : (
                  currentItems.length <= 0 && (
                      <Row className="justify-content-center">
                        <Col xs="auto" className="text-center mb-4">
                          <img src={FigQuestion} alt="Imagem de pergunta"
                               className="mb-4 img-fluid"
                               style={{width: '250px'}}/>
                          <span className="text-primary d-block fw-bold">Você não possui atendimentos agendados.</span>
                        </Col>
                      </Row>
                  )
              )}
              {currentItems.length > 0 && (
                  <CustomPagination
                      outData={appointmentDate}
                      changeDate={date}
                  />
              )}
            </Col>
          </Row>
        </Container>

        <ToastContainer id={'toastcontainer'}
                        position={'top-center'}
                        className={'p-3'}
                        style={{zIndex: 1}}>
          {toast.show && (
              <CustomToast delay={toast.delay}
                           title={toast.title}
                           content={toast.content}
                           variant={toast.variant}
                           show={toast.show}/>
          )}
        </ToastContainer>
      </>
  );
};

export default Dashboard;
